<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : '修改'"
    :close-on-click-modal="false"
    :visible.sync="visible"
  >
    <el-form
      :model="dataForm"
      :rules="dataRule"
      ref="dataForm"
      label-width="120px"
    >
      <el-form-item label="部门" prop="did">
        <el-select
          v-model="dataForm.did"
          placeholder="请选择部门"
          style="width: 100%"
          clearable
          filterable
          @change="selectDepartment"
        >
          <el-option
            v-for="item in departmentList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="班组" prop="tid">
        <el-select
          v-model="dataForm.tid"
          placeholder="请选择班组"
          style="width: 100%"
          clearable
          filterable
          @focus="teamFocus"
          @change="selectTeam"
        >
          <el-option
            v-for="item in teamList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="车辆" prop="carId">
        <el-select
          v-model="dataForm.carId"
          clearable
          filterable
          style="width: 100%"
          :placeholder="dataForm.did ? '请选择车辆' : '请先选择部门'"
          @change="carIdChange"
        >
          <el-option
            v-for="item in carList"
            :key="item.id"
            :label="item.number"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="检查人" prop="eid">
        <el-select
          v-model="dataForm.eid"
          clearable
          filterable
          style="width: 100%"
          :placeholder="dataForm.did ? '请选择员工' : '请先选择部门'"
          @change="eidChange"
        >
          <el-option
            v-for="item in employeeList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="检查项目" prop="jcxm">
        <el-input
          v-model="dataForm.jcxm"
          placeholder="检查项目"
          type="textarea"
          rows="6"
        ></el-input>
      </el-form-item>
      <el-form-item v-if="dataForm.id" label="检查时间" prop="checkDate">
        <el-date-picker
          style="width: 100%"
          v-model="dataForm.checkDate"
          value-format="yyyy-MM-dd HH:mm:ss"
          type="date"
          placeholder="选择日期"
          clearable
        ></el-date-picker>
      </el-form-item>
      <el-form-item v-if="dataForm.id" label="检查结果" prop="checkResult">
        <editor ref="editor1" v-model="dataForm.checkResult"></editor>
      </el-form-item>
      <el-form-item v-if="dataForm.id" label="处理情况" prop="clqk">
        <editor ref="editor2" v-model="dataForm.clqk"></editor>
      </el-form-item>
      <el-form-item v-if="dataForm.id" label="状态" prop="status">
        <el-select v-model="dataForm.status" disabled style="width: 100%">
          <el-option
            v-for="item in patrol_statusOpt"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-if="dataForm.status > 1" label="审核意见" prop="remarks">
        <el-input
          v-model="dataForm.remarks"
          placeholder="审核意见"
          disabled
        ></el-input>
      </el-form-item>
    </el-form>
    <div v-if="dataForm.id" style="padding: 10px 0; font-size: 16px">
      检查项目详情
    </div>
    <el-table
      v-if="dataForm.id"
      :data="dataForm.jsonData"
      border
      style="width: 100%"
    >
      <el-table-column label="标题" prop="title"></el-table-column>
      <el-table-column label="选项" header-align="center" width="380">
        <template slot-scope="scope">
          <div>
            <el-radio-group
              class="opt-radio-group"
              v-model="scope.row.optionIndex"
            >
              <el-radio label="0" disabled>符合</el-radio>
              <el-radio label="1" disabled>不符合</el-radio>
              <el-radio label="2" disabled>未检查</el-radio>
              <el-radio label="3" disabled>不适用</el-radio>
            </el-radio-group>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" v-if="flag != 0" @click="dataFormSubmit()"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import Editor from "@/components/editor.vue";
export default {
  components: {
    Editor,
  },
  data() {
    return {
      visible: false,
      //   状态
      patrol_statusOpt: [
        { label: "已派发例检任务", value: 0 },
        { label: "已填写情况", value: 1 },
        { label: "审核不合格，重新填写", value: 2 },
        { label: "已归档入库", value: 3 },
      ],
      departmentList: [], // 部门列表
      teamList: [], // 班组列表
      employeeList: [], //员工列表
      carList: [], // 车辆列表
      dataForm: {
        id: 0,
        did: "",
        dname: "",
        carId: "",
        carNumber: "",
        tid: "",
        tname: "",
        eid: "",
        ename: "",
        checkDate: "",
        checkResult: "",
        clqk: "",
        status: "",
        remarks: "",
        createDate: "",
        updateDate: "",
        isDeleted: "",
        type: 1,
        log: "",
        jcxm: "",
        jsonData: [],
      },
      flag: 0,
      dataRule: {
        did: [{ required: true, message: "部门不能为空", trigger: "blur" }],
        carId: [{ required: true, message: "车辆不能为空", trigger: "blur" }],
        eid: [{ required: true, message: "检查人不能为空", trigger: "blur" }],
        jcxm: [
          { required: true, message: "检查项目不能为空", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    // 获取车辆列表
    getCarList() {
      let carListDF = {
        did: this.dataForm.did,
        tid: this.dataForm.tid,
        page: 1,
        limit: 99999,
      };

      this.$http.car.list(carListDF).then((res) => {
        if (res.data) {
          this.carList = res.data.list || [];
        } else {
          this.carList = [];
        }
      });
    },
    // 选择的车辆发生变化
    carIdChange(e) {
      this.dataForm.carNumber = "";
      if (e) {
        // 获取当前选择的车辆
        let result = this.carList.filter((ele) => {
          return ele.id == e;
        });
        if (result.length > 0) {
          this.dataForm.carNumber = result[0].number;
        }
      }
    },
    // 获取员工列表
    getEmployeeList() {
      let employeeListDF = {
        did: this.dataForm.did,
        tid: this.dataForm.tid,
        page: 1,
        limit: 99999,
      };

      this.$http.employee.list(employeeListDF).then((res) => {
        if (res.data) {
          this.employeeList = res.data.list || [];
        } else {
          this.employeeList = [];
        }
      });
    },
    // 选择的员工发生变化
    eidChange(e) {
      this.dataForm.ename = "";
      if (e) {
        // 获取当前选择的员工名字
        let result = this.employeeList.filter((ele) => {
          return ele.id == e;
        });
        if (result.length > 0) {
          this.dataForm.ename = result[0].name;
        }
      }
    },
    // 选择班组
    selectTeam(value) {
      this.teamList.forEach((item) => {
        if (item.id == value) {
          this.dataForm.tname = item.name;
        }
      });
    },
    // 获取班组列表
    getTeamList() {
      this.$http.team
        .list({ limit: 99999, page: 1, did: this.dataForm.departmentId })
        .then((res) => {
          if (res && res.code == 0) {
            this.teamList = res.data.list;
          }
        });
    },
    // 班组选择框获得焦点
    teamFocus() {
      if (!this.dataForm.did) {
        this.$message.error("请先选择部门");
        return;
      }
      this.getTeamList();
    },
    // 选择部门
    selectDepartment(value) {
      this.getEmployeeList();
      this.getCarList();
      this.departmentList.forEach((item) => {
        if (item.id == value) {
          this.dataForm.dname = item.name;
        }
      });
    },
    // 获取部门列表
    getDepartmentList() {
      this.$http.department.list({ limit: 9999, page: 1 }).then((res) => {
        if (res && res.code == 0) {
          this.departmentList = res.data.list;
          this.getEmployeeList();
          this.getTeamList();
          this.getCarList();
        }
      });
    },
    init(id, flag) {
      this.dataForm.jsonData = "";
      this.flag = flag;
      this.getDepartmentList();
      this.dataForm.id = id || 0;
      this.visible = true;
      this.$nextTick(() => {
        this.$refs["dataForm"].resetFields();
        if (this.dataForm.id) {
          this.$http.carcheck.info({ id: this.dataForm.id }).then((data) => {
            if (data.data.jsonData) {
              data.data.jsonData = JSON.parse(data.data.jsonData);
            }
            if (data && data.code === 0) {
              this.dataForm = data.data;
              this.getTeamList();
              this.$refs.editor1.destroy();
              this.$refs.editor1.init(this.dataForm.checkResult);
              this.$refs.editor2.destroy();
              this.$refs.editor2.init(this.dataForm.clqk);
            }
          });
        } else {
          this.$refs.editor1.destroy();
          this.$refs.editor1.init(this.dataForm.checkResult);
          this.$refs.editor2.destroy();
          this.$refs.editor2.init(this.dataForm.clqk);
        }
      });
    },
    dataFormSubmit() {
      this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          let requestDate = this.dataForm;

          if (this.dataForm.id) {
            // 修改
            this.$http.carcheck.update(requestDate).then((data) => {
              if (data && data.code === 0) {
                this.$message({
                  message: "修改操作成功",
                  type: "success",
                  duration: 1500,
                  onClose: () => {
                    this.visible = false;
                    this.$emit("refreshDataList");
                  },
                });
              } else {
                this.$message.error(data.msg);
              }
            });
          } else {
            // 新增
            requestDate.id = "";
            this.$http.carcheck.save(requestDate).then((data) => {
              if (data && data.code === 0) {
                this.$message({
                  message: "添加成功",
                  type: "success",
                  duration: 1500,
                  onClose: () => {
                    this.visible = false;
                    this.$emit("refreshDataList");
                  },
                });
              } else {
                this.$message.error(data.msg);
              }
            });
          }
        }
      });
    },
  },
};
</script>
